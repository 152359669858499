.logo {
  /* height: 50px; */
  width: 100px;
}
.text-input {
  border-radius: 50px;
  width: 100px;
  height: 25px;
}
*:focus {
  outline: none;
}
.navbar {
  padding: 7px;
  justify-content: space-between;
}
