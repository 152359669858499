.middle {
  display: inline-block;
  justify-content: center;
}

a.nav-link {
  padding: 2px;
}

.nav-item:focus {
  outline: none;
  outline-offset: none;
}
