.footer{
    justify-content: center;
}

/* .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: black;
  text-align: center;
  justify-content: center;
} */
