Button {
  margin-right: 5px;
}

#action {
  width: 20%;
}
td {
  text-align: center;
}

input {
  border: none;
  background: none;
}
