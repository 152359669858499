.border {
    
    width: 65%;
    border: 1px solid #ccc;
    box-shadow: 3px 2px 2px #ccc;
    margin: 5% 0;
}

.margin {
    margin: 3%;
}