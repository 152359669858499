.right_pannel {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rebeccapurple; */
  flex-direction: column;
  /* height: 85vh; */
  margin: 0 0 0 15PX;

}

.right{
  background-color: #f8f9fc;
  margin: 0 0 0 15PX;

}

.company_name {
  /* border: 1px solid orchid; */
  width: 70%;
  height: 30%;
  margin: 10px 0
}

.others {
  /* border: 1px solid orchid; */
  width: 70%;
  height: 70%;
  margin: 10px 0
}
