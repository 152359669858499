.left_pannel {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rebeccapurple; */
  flex-direction: column;
  /* height: 85vh; */
  margin: 0 15px 0 0;
  /* background-color: #f8f9fc; */

}

.left{
  background-color: #f8f9fc;

}

.company_logo {
  /* border: 1px solid orchid; */
  width: 70%;
  height: 20%;
  margin:10px 0;
}

.menu {
    /* border: 1px solid orchid; */
    width: 70%;
    height: 70%;
    margin: 10px 0
  }
