.row {
  justify-content: center;
}

/*
.container {
  margin: 0;
} */

.body {
  display: flex;
  /* margin-bottom: 2%; */
  height: 100vh;
  /* padding: 1% 0; */
}

.middle {
  width: 80%;
}

.right {
  margin-left: 3%;
}

.left {
  margin-right: 3%;
}

.body-wrapper {
  width: 80%;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
