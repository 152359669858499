
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}


html,
body {
  height: 100%;
}